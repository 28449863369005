import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CheckListItem } from 'src/components/CheckListItem';
import { Flex } from 'src/components/Flex';
import { MediaSection } from 'src/components/MediaSection';
import { Methods } from 'src/components/Methods/Methods';
import { StreamingIntegrationSection } from 'src/components/Methods/streaming/StreamingIntegrationSection';
import { Paragraph } from 'src/components/Paragraph';
import SoftwareHero from 'src/components/Software/Hero';
import { ThemeProvider } from 'src/components/ThemeProvider';
import { PrinciplesSection } from 'src/homepage/Principles';

export default function XenforoPage({ data }) {
    const sliderList = [
        data.cpanel.childImageSharp.fluid,
        data.cubecraft.childImageSharp.fluid,
        data.cyclone.childImageSharp.fluid,
        data.hometheater.childImageSharp.fluid,
        data.ign.childImageSharp.fluid,
        data.paradox.childImageSharp.fluid,
        data.pedelecs.childImageSharp.fluid,
        data.resetera.childImageSharp.fluid,
        data.tomshardware.childImageSharp.fluid,
    ];

    return (
        <Layout
            pageTitleFull="Grow your XenForo community with Audentio"
            siteDescription="Grow your XenForo community strategically with quality design and development, a mobile app, and more!"
            contactForm
        >
            <SoftwareHero
                title="Grow your XenForo community with us"
                carousel={sliderList}
            >
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title: 'Reach the next step in growth',
                        },
                        {
                            title:
                                'Partner with people passionate about communities and experts in building them',
                        },
                        {
                            title:
                                'Increase engagement and revenue opportunities strategically',
                        },
                        {
                            title:
                                'Achieve your goals with help from a dedicated team supporting the community',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            headingStyle={{
                                fontWeight: 'normal',
                            }}
                            as="li"
                        />
                    ))}
                </Box>
            </SoftwareHero>

            {/* This isn't really a media section, but the component allows for a custom content section, and helps us stick with a consistent layout and structure */}
            <MediaSection
                id="strategy"
                title="Have the community you deserve with our tried-and-true strategy."
                flipped
                bg="primaryBackground"
                customMedia={
                    <Flex>
                        <Paragraph color="bodyText" maxW={525}>
                            Our team of community experts, including designers,
                            developers, and community managers, can help your
                            community grow with an emphasis on better engagement
                            and more monetization opportunities.
                        </Paragraph>
                    </Flex>
                }
            />

            <PrinciplesSection />

            <Methods
                bg={{
                    _: 'primaryBackground',
                    lg: 'secondaryBackground',
                }}
                pt={{
                    _: null,
                    lg: 0,
                }}
            />

            <ThemeProvider defaultMode="dark">
                <MediaSection
                    id="streaming"
                    title="Engage directly with your audience by streaming"
                    image={data.streaminterface.childImageSharp.fluid}
                    bg="primaryBackground"
                >
                    <Paragraph color="bodyText">
                        Streaming from your own platform gives you more control
                        over your brand; integration with your community
                        features; monetization without percentage cuts; and the
                        ability to keep engagement on your platform.
                    </Paragraph>
                    <Box pt="spacing-lg">
                        <Button href="/contact?source=streaming" fill="white">
                            learn more about streaming
                        </Button>
                    </Box>
                </MediaSection>
            </ThemeProvider>

            <MediaSection
                id="revenue"
                title="Expand your revenue streams"
                image={data.revenuestreams.childImageSharp.fluid}
                bg="primaryBackground"
            >
                Monetize in ways that fit your community and goals with
                customizable subscription tiers, donations, event ticket sales,
                web and mobile ads, and sponsors.
            </MediaSection>

            <StreamingIntegrationSection
                id="streaming"
                title="Give your community a native app for easier engagement"
            />
        </Layout>
    );
}

export const query = graphql`
    query XenforoQuery {
        cpanel: file(
            relativePath: { eq: "images/xfsoftware/slider/cpanel.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cubecraft: file(
            relativePath: { eq: "images/xfsoftware/slider/cubecraft.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cyclone: file(
            relativePath: { eq: "images/xfsoftware/slider/cyclone.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        hometheater: file(
            relativePath: { eq: "images/xfsoftware/slider/hometheater.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/xfsoftware/slider/ign.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        paradox: file(
            relativePath: { eq: "images/xfsoftware/slider/paradox.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pedelecs: file(
            relativePath: { eq: "images/xfsoftware/slider/pedelecs.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        resetera: file(
            relativePath: { eq: "images/xfsoftware/slider/resetera.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        tomshardware: file(
            relativePath: { eq: "images/xfsoftware/slider/toms-hardware.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        revenuestreams: file(
            relativePath: { eq: "images/xfsoftware/revenue-streams.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streaminterface: file(
            relativePath: { eq: "images/xfsoftware/stream-interface.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
